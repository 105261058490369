/** @format */

import React from "react";
import {
  Container,
  AspectRatio,
  Image,
  Flex,
  Box,
  Heading,
  Text,
} from "@chakra-ui/react";
import WorksImage from "../static/images/works.jpg";
import {MyProjects} from "../../src/utils/helpers";
import ProjectCard from "../components/Projects/partials/projectCard";
import SEO from "../components/seo";

const Works = () => {
  return (
    <>
      <AspectRatio ratio={{base: 6 / 4, md: 12 / 4}}>
        <Image src={WorksImage} alt="naruto" objectFit="cover" />
      </AspectRatio>

      <Container px={{base: 0, sm: 4}}>
        <Box my={{lg: "50px"}}>
          <h1
            style={{marginTop: "20px", fontSize: "24px", textAlign: "center"}}
          >
            We collaborate with clients in Ghana and UK to create exceptional
            digital products and services for the future.
          </h1>
        </Box>

        <Box textAlign="center">
          <Heading my={5}>Our cases</Heading>
          <Text fontSize="2xl">
            No challenge is too big for us. This is a selection of what we are
            working on now.
          </Text>
        </Box>
        <Flex
          my={5}
          direction={{base: "column", md: "row"}}
          justify={{md: "center"}}
          gap="1rem"
          wrap="wrap"
        >
          {MyProjects.map(({title, description, page}) => (
            <Flex key={title + description}>
              <ProjectCard
                title={title}
                description={description}
                page={page}
              />
            </Flex>
          ))}
        </Flex>
      </Container>
    </>
  );
};

export default Works;

export const Head = () => <SEO title="Your trusted IT consult - Works" />;

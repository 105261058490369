/** @format */

import React from "react";
import PropTypes from "prop-types";
import {Heading, Link, Text, VStack} from "@chakra-ui/react";

const ProjectCard = ({title, description, page}) => {
  return (
    <VStack
      h="360px"
      w={{base: "100%", md: "360px"}}
      border="2px solid"
      spacing={8}
    >
      <Heading mt={8} pt={8}>
        {title}
      </Heading>
      <Text>{description}</Text>
      <Link href={page}> View more</Link>
    </VStack>
  );
};

ProjectCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  page: PropTypes.string.isRequired,
};

export default ProjectCard;
